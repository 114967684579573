import {
  HomePageToolkit,
  HomePageCompanyLogo,
  TemplateBackstageLogoIcon,
  HeaderWorldClock,
  ClockConfig,
} from '@backstage/plugin-home';
import { Content, Page, InfoCard, Header } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import LogoFull from '../Root/LogoPurpleFull';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  svg: {
    width: 600,
    height: 294,
  },
  path: {
    fill: '#black',
  },
}));

const clockConfigs: ClockConfig[] = [
  {
    label: 'New York',
    timeZone: 'America/New_York',
  },
  {
    label: 'Columbia',
    timeZone: 'America/Bogota',
  },
  {
    label: 'London',
    timeZone: 'UTC',
  },
  {
    label: 'Berlin',
    timeZone: 'Europe/Berlin',
  },
  {
    label: 'Tokyo',
    timeZone: 'Asia/Tokyo',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

export const HomePage = () => {
  const classes = useStyles();
  const { svg } = useLogoStyles();

  return (
    <SearchContextProvider>
      <Page themeId="tool">
        <Header title="Ayu" pageTitleOverride="Home">
          <HeaderWorldClock
            clockConfigs={clockConfigs}
            customTimeFormat={timeFormat}
          />
        </Header>
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            <HomePageCompanyLogo className={svg} logo={<LogoFull />} />
            <Grid container item xs={12} justifyContent="center">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search"
              />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <InfoCard title="Welcome to Ayu">
                  {/* placeholder for content */}
                  <div>
                    <Typography variant="body1">
                      Ayu, a home for navigating the deepc organization.
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      Find links to critical sub-systems as well as automation
                      tools and workflows to scale deepc 🚀.
                    </Typography>
                  </div>
                </InfoCard>
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit
                  tools={[
                    {
                      url: 'https://deepc.slite.com',
                      label: 'Slite',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://zeroheight.com/814779e95/p/9330c2-deepc-brand-playbook',
                      label: 'Brand Playbook',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://gitlab.com/deepc',
                      label: 'Gitlab',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://deepc-ai.slack.com',
                      label: 'Slack',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                    {
                      url: 'https://bluefin.apps.deepc-engineering.de',
                      label: 'Bluefin Compliance',
                      icon: <TemplateBackstageLogoIcon />,
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
