import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const bluefinPlugin = createPlugin({
  id: 'bluefin',
  routes: {
    root: rootRouteRef,
  },
});

export const BluefinPage = bluefinPlugin.provide(
  createRoutableExtension({
    name: 'BluefinPage',
    component: () =>
      import('./components/BluefinComponent').then(m => m.BluefinComponent),
    mountPoint: rootRouteRef,
  }),
);
