import React from 'react';

const LogoPurpleFull = () => {
  return (
    <svg
      width="600"
      height="294"
      viewBox="0 0 600 294"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13_789)">
        <path
          d="M502.053 165.695C500.28 167.668 498.297 169.399 495.986 170.753C491.76 173.228 487.147 173.772 482.382 173.504C476.492 173.17 471.215 171.238 466.887 167.158C462.762 163.261 460.476 158.403 459.804 152.818C459.048 146.589 460.09 140.711 463.594 135.393C467.333 129.732 472.727 126.605 479.349 125.443C484.987 124.456 490.373 125.209 495.482 127.818C497.994 129.105 500.524 131.271 501.633 133.144C499.91 134.373 498.179 135.602 496.381 136.89C494.978 134.85 493.196 133.361 491.062 132.299C483.979 128.779 474.568 131.396 470.022 138.136C465.375 145.025 465.652 156.213 471.786 162.601C474.568 165.494 477.979 167.149 481.954 167.584C486.659 168.094 491.071 167.367 494.743 164.081C495.516 163.387 496.306 162.718 497.23 161.899C498.683 162.986 500.364 164.24 502.045 165.486C502.053 165.561 502.053 165.628 502.053 165.695Z"
          fill="#040033"
        />
        <path
          d="M448.151 144.164C447.243 139.206 445.067 134.841 441.353 131.38C435.773 126.179 429.034 124.306 421.556 125.293C416.27 125.995 411.699 128.294 408.305 132.55C407.901 133.06 407.523 133.595 407.128 134.114C407.053 134.097 406.985 134.08 406.91 134.064C406.91 131.463 406.91 128.863 406.91 126.296C404.557 126.296 402.355 126.296 400.062 126.296C400.062 149.566 400.062 172.752 400.062 195.946C402.347 195.946 404.633 195.946 406.918 195.946C406.918 185.561 406.918 175.168 406.918 164.641C408.179 165.987 409.17 167.266 410.38 168.295C414.834 172.108 420.06 173.805 425.908 173.529C433.941 173.153 440.42 169.85 444.789 163.019C448.487 157.225 449.369 150.845 448.151 144.164ZM438.395 160.184C435.118 165.444 430.118 167.634 426.455 167.693C414.598 167.693 407.221 161.029 406.296 151.706C405.691 145.644 407.137 140.209 411.531 135.828C416.43 130.936 422.455 129.741 429.001 131.472C435.446 133.177 439.185 137.692 440.723 143.988C442.101 149.657 441.529 155.159 438.395 160.184Z"
          fill="#040033"
        />
        <path
          d="M385.768 149.884C385.608 147.626 385.684 145.335 385.331 143.111C384.507 137.835 382.222 133.27 378.012 129.816C374.483 126.915 370.366 125.468 365.828 125.134C358.249 124.574 351.686 126.79 346.477 132.367C341.695 137.484 339.838 143.688 340.141 150.578C340.435 157.283 342.88 163.111 347.947 167.576C354.989 173.789 363.24 174.842 372.071 172.351C377.063 170.946 380.886 167.835 384.104 163.747C382.382 162.401 380.701 161.105 379.029 159.792C372.5 168.856 362.845 168.822 357.173 166.255C349.695 162.877 347.124 155.486 347.392 151.096C347.779 151.096 348.174 151.096 348.569 151.096C360.56 151.096 372.542 151.088 384.533 151.121C385.516 151.129 385.835 150.828 385.768 149.884ZM347.216 145.586C347.628 144.031 347.863 142.542 348.409 141.179C351.938 132.417 361.299 128.487 370.265 132.04C375.113 133.964 377.441 138.027 378.474 142.927C378.567 143.353 378.626 143.78 378.668 144.215C378.718 144.641 378.726 145.076 378.76 145.594C368.315 145.586 357.913 145.586 347.216 145.586Z"
          fill="#040033"
        />
        <path
          d="M262.137 98C262.137 110.032 262.137 121.997 262.137 133.962C260.524 131.997 258.868 130.208 256.81 128.845C252.163 125.751 247.046 124.581 241.483 125.199C234.013 126.027 228.114 129.422 224.123 135.793C220.653 141.329 219.77 147.424 220.779 153.795C221.753 159.899 224.568 165.016 229.551 168.821C234.5 172.6 240.197 174.005 246.323 173.47C252.466 172.926 257.675 170.426 261.456 165.384C261.599 165.192 261.784 165.025 262.162 164.607C262.162 167.374 262.162 169.841 262.162 172.299C264.481 172.299 266.649 172.299 268.817 172.299C268.817 147.482 268.817 122.75 268.817 98.0084C266.557 98 264.397 98 262.137 98ZM251.835 166.446C242.828 169.983 230.055 166.739 227.929 152.683C227.299 148.536 227.736 144.497 229.425 140.635C232.282 134.104 238.584 130.409 246.138 130.81C254.499 131.253 261.322 137.407 262.566 145.668C262.742 146.855 262.809 148.051 262.927 149.247C262.835 157.223 258.742 163.737 251.835 166.446Z"
          fill="#040033"
        />
        <path
          d="M329.126 149.723C329.101 147.959 329.109 146.178 328.908 144.43C328.286 139.104 326.336 134.338 322.32 130.634C315.11 123.979 302.994 123.067 294.423 128.577C287.272 133.168 283.894 139.907 283.499 148.243C282.911 160.677 291.179 172.056 304.573 173.394C312.758 174.214 319.917 171.989 325.639 165.894C326.269 165.225 326.849 164.514 327.496 163.77C325.723 162.391 324.068 161.095 322.37 159.773C319.875 163.269 316.774 165.877 312.674 167.031C308.581 168.193 304.506 168.043 300.574 166.27C293.364 163.034 290.6 156.094 290.726 151.103C291.205 151.103 291.599 151.103 292.003 151.103C303.926 151.103 315.85 151.103 327.765 151.103C329.109 151.103 329.151 151.094 329.126 149.723ZM290.742 145.618C290.877 144.748 290.944 143.937 291.137 143.151C293.062 135.434 300.036 130.367 308.094 130.818C315.925 131.253 321.396 136.537 322.051 144.322C322.085 144.715 322.118 145.108 322.16 145.618C311.665 145.618 301.305 145.618 290.742 145.618Z"
          fill="#040033"
        />
        <path
          d="M191.789 140.45C189.212 140.442 187.146 138.395 187.162 135.846C187.17 133.305 189.268 131.233 191.813 131.249C194.383 131.257 196.448 133.328 196.424 135.877C196.408 138.45 194.383 140.45 191.789 140.45Z"
          fill="#7545FF"
        />
        <path
          d="M174.08 180.31C174.064 182.819 172.015 184.827 169.477 184.835C166.884 184.843 164.802 182.74 164.834 180.135C164.866 177.65 166.956 175.61 169.461 175.61C172.079 175.61 174.104 177.673 174.08 180.31Z"
          fill="#7545FF"
        />
        <path
          d="M191.789 151.545C189.211 151.529 187.162 149.49 187.162 146.933C187.162 144.4 189.267 142.329 191.821 142.337C194.375 142.345 196.456 144.424 196.432 146.973C196.416 149.538 194.375 151.553 191.789 151.545Z"
          fill="#7545FF"
        />
        <path
          d="M158.314 98C160.908 98.008 162.941 100.023 162.933 102.596C162.925 105.137 160.82 107.224 158.29 107.216C155.752 107.208 153.663 105.113 153.671 102.572C153.679 99.9994 155.712 97.9921 158.314 98Z"
          fill="#7545FF"
        />
        <path
          d="M185.252 169.214C185.212 171.723 183.139 173.763 180.641 173.755C178.064 173.747 175.902 171.564 175.974 169.047C176.046 166.514 178.208 164.443 180.713 164.515C183.307 164.586 185.3 166.649 185.252 169.214Z"
          fill="#7545FF"
        />
        <path
          d="M174.08 113.668C174.08 116.241 172.039 118.288 169.469 118.288C166.915 118.288 164.834 116.217 164.834 113.668C164.826 111.127 166.899 109.072 169.469 109.072C172.079 109.072 174.08 111.071 174.08 113.668Z"
          fill="#7545FF"
        />
        <path
          d="M191.754 162.618C189.168 162.602 187.119 160.539 187.159 157.99C187.199 155.449 189.312 153.377 191.842 153.401C194.387 153.425 196.461 155.544 196.429 158.077C196.389 160.666 194.363 162.634 191.754 162.618Z"
          fill="#7545FF"
        />
        <path
          d="M185.255 124.75C185.263 127.322 183.221 129.37 180.652 129.37C178.09 129.37 175.993 127.314 175.977 124.781C175.961 122.272 178.066 120.177 180.62 120.169C183.213 120.161 185.247 122.177 185.255 124.75Z"
          fill="#7545FF"
        />
        <path
          d="M158.309 186.73C160.895 186.73 162.944 188.761 162.936 191.318C162.936 193.851 160.831 195.946 158.301 195.946C155.763 195.946 153.666 193.843 153.666 191.318C153.682 188.745 155.723 186.722 158.309 186.73Z"
          fill="#7545FF"
        />
        <path
          d="M142.544 191.279C142.552 188.706 144.577 186.722 147.187 186.738C149.765 186.754 151.79 188.793 151.774 191.35C151.75 193.883 149.637 195.962 147.115 195.931C144.553 195.907 142.536 193.844 142.544 191.279Z"
          fill="#7545FF"
        />
        <path
          d="M142.544 102.517C142.56 99.9525 144.593 97.9929 147.211 98.0168C149.789 98.0327 151.798 100.08 151.766 102.653C151.734 105.17 149.581 107.265 147.075 107.209C144.529 107.154 142.528 105.082 142.544 102.517Z"
          fill="#7545FF"
        />
        <path
          d="M131.385 102.604C131.369 100.023 133.386 98.0239 135.988 98.0239C138.557 98.0239 140.631 100.079 140.615 102.612C140.599 105.105 138.445 107.216 135.932 107.192C133.434 107.169 131.393 105.113 131.385 102.604Z"
          fill="#7545FF"
        />
        <path
          d="M135.972 186.746C138.526 186.738 140.623 188.809 140.615 191.326C140.607 193.82 138.462 195.931 135.948 195.923C133.466 195.907 131.409 193.852 131.385 191.35C131.361 188.769 133.37 186.754 135.972 186.746Z"
          fill="#7545FF"
        />
        <path
          d="M129.389 113.701C129.373 116.25 127.355 118.257 124.818 118.249C122.224 118.233 120.247 116.218 120.263 113.597C120.279 111.056 122.296 109.096 124.874 109.12C127.427 109.144 129.405 111.144 129.389 113.701Z"
          fill="#7545FF"
        />
        <path
          d="M109.113 124.629C109.177 122.104 111.242 120.153 113.796 120.216C116.326 120.28 118.279 122.351 118.215 124.892C118.151 127.417 116.061 129.393 113.532 129.305C110.97 129.218 109.049 127.186 109.113 124.629Z"
          fill="#7545FF"
        />
        <path
          d="M124.858 175.674C127.427 175.69 129.397 177.682 129.389 180.239C129.373 182.851 127.363 184.803 124.738 184.763C122.16 184.723 120.255 182.764 120.263 180.159C120.279 177.618 122.288 175.65 124.858 175.674Z"
          fill="#7545FF"
        />
        <path
          d="M113.619 164.617C116.196 164.594 118.19 166.529 118.214 169.086C118.246 171.619 116.268 173.627 113.707 173.659C111.153 173.69 109.144 171.723 109.112 169.174C109.08 166.617 111.041 164.633 113.619 164.617Z"
          fill="#7545FF"
        />
        <path
          d="M107.012 135.885C107.004 138.37 105.019 140.362 102.513 140.378C99.9753 140.394 97.998 138.402 97.998 135.821C97.998 133.304 99.9593 131.337 102.473 131.313C104.955 131.289 107.012 133.368 107.012 135.885Z"
          fill="#7545FF"
        />
        <path
          d="M107.013 147.006C106.997 149.491 105.003 151.459 102.49 151.459C99.92 151.451 97.9987 149.491 98.0147 146.894C98.0308 144.377 100.008 142.41 102.514 142.426C105.035 142.434 107.021 144.457 107.013 147.006Z"
          fill="#7545FF"
        />
        <path
          d="M107.012 158.077C107.004 160.594 105.043 162.53 102.505 162.53C99.9033 162.53 97.9901 160.602 97.9981 157.997C98.0061 155.48 99.9914 153.497 102.481 153.513C105.035 153.528 107.02 155.528 107.012 158.077Z"
          fill="#7545FF"
        />
        <path
          d="M154.016 180.191C154.04 177.841 155.953 175.937 158.29 175.921C160.668 175.913 162.605 177.865 162.589 180.255C162.573 182.652 160.628 184.54 158.218 184.5C155.865 184.461 154 182.549 154.016 180.191Z"
          fill="#7545FF"
        />
        <path
          d="M176.335 158.069C176.319 155.727 178.208 153.792 180.554 153.752C182.939 153.712 184.917 155.616 184.949 157.998C184.981 160.339 182.995 162.315 180.618 162.315C178.272 162.315 176.351 160.411 176.335 158.069Z"
          fill="#7545FF"
        />
        <path
          d="M180.654 142.688C183.024 142.696 184.937 144.576 184.953 146.918C184.969 149.267 182.984 151.251 180.622 151.235C178.236 151.227 176.307 149.267 176.339 146.886C176.363 144.544 178.277 142.68 180.654 142.688Z"
          fill="#7545FF"
        />
        <path
          d="M173.734 169.189C173.71 171.563 171.821 173.435 169.459 173.427C167.074 173.419 165.184 171.491 165.2 169.07C165.208 166.728 167.13 164.832 169.491 164.848C171.893 164.856 173.758 166.76 173.734 169.189Z"
          fill="#7545FF"
        />
        <path
          d="M180.642 140.107C178.208 140.107 176.319 138.219 176.335 135.813C176.351 133.463 178.256 131.592 180.626 131.592C183.019 131.592 184.973 133.535 184.949 135.901C184.925 138.243 183.019 140.107 180.642 140.107Z"
          fill="#7545FF"
        />
        <path
          d="M162.598 113.638C162.614 116.011 160.757 117.907 158.395 117.931C155.954 117.963 154.041 116.115 154.025 113.709C154.001 111.319 155.946 109.376 158.323 109.384C160.677 109.4 162.582 111.296 162.598 113.638Z"
          fill="#7545FF"
        />
        <path
          d="M173.733 124.805C173.717 127.195 171.884 129.019 169.474 129.019C167.033 129.019 165.183 127.163 165.191 124.725C165.199 122.312 167.097 120.488 169.554 120.519C171.948 120.543 173.749 122.391 173.733 124.805Z"
          fill="#7545FF"
        />
        <path
          d="M151.411 180.183C151.419 182.588 149.53 184.492 147.136 184.492C144.767 184.492 142.902 182.636 142.894 180.262C142.878 177.833 144.759 175.929 147.16 175.937C149.506 175.945 151.403 177.841 151.411 180.183Z"
          fill="#7545FF"
        />
        <path
          d="M140.266 180.182C140.282 182.516 138.393 184.444 136.047 184.476C133.653 184.508 131.692 182.588 131.7 180.214C131.708 177.88 133.621 175.968 135.967 175.944C138.288 175.929 140.242 177.856 140.266 180.182Z"
          fill="#7545FF"
        />
        <path
          d="M151.41 113.668C151.41 116.09 149.537 117.946 147.104 117.93C144.718 117.914 142.893 116.074 142.885 113.684C142.885 111.271 144.79 109.367 147.184 109.407C149.561 109.438 151.418 111.302 151.41 113.668Z"
          fill="#7545FF"
        />
        <path
          d="M135.967 117.914C133.605 117.914 131.692 116.018 131.7 113.684C131.7 111.35 133.597 109.446 135.951 109.422C138.328 109.398 140.282 111.342 140.266 113.716C140.242 116.05 138.337 117.914 135.967 117.914Z"
          fill="#7545FF"
        />
        <path
          d="M120.596 169.126C120.588 166.76 122.461 164.888 124.83 164.88C127.168 164.88 129.081 166.784 129.089 169.126C129.089 171.46 127.168 173.372 124.83 173.38C122.453 173.388 120.596 171.516 120.596 169.126Z"
          fill="#7545FF"
        />
        <path
          d="M124.83 120.559C127.224 120.567 129.089 122.423 129.081 124.797C129.065 127.147 127.152 129.003 124.766 128.987C122.413 128.971 120.604 127.139 120.596 124.781C120.588 122.367 122.413 120.551 124.83 120.559Z"
          fill="#7545FF"
        />
        <path
          d="M113.645 162.219C111.235 162.211 109.354 160.331 109.402 157.981C109.45 155.687 111.364 153.807 113.661 153.807C115.983 153.807 117.96 155.807 117.912 158.093C117.864 160.435 116.007 162.219 113.645 162.219Z"
          fill="#7545FF"
        />
        <path
          d="M113.645 140.044C111.259 140.044 109.362 138.14 109.402 135.798C109.442 133.496 111.339 131.648 113.661 131.648C116.014 131.648 117.952 133.591 117.912 135.909C117.871 138.219 115.99 140.044 113.645 140.044Z"
          fill="#7545FF"
        />
        <path
          d="M113.697 151.139C111.32 151.154 109.39 149.259 109.406 146.933C109.422 144.623 111.304 142.751 113.625 142.735C115.971 142.719 117.924 144.631 117.916 146.957C117.908 149.259 116.027 151.123 113.697 151.139Z"
          fill="#7545FF"
        />
        <path
          d="M169.499 131.895C171.677 131.918 173.462 133.719 173.454 135.877C173.446 138.036 171.629 139.812 169.451 139.804C167.218 139.797 165.497 138.004 165.537 135.734C165.577 133.591 167.354 131.871 169.499 131.895Z"
          fill="#7545FF"
        />
        <path
          d="M165.541 158.022C165.549 155.8 167.262 154.095 169.488 154.103C171.681 154.111 173.466 155.887 173.458 158.054C173.45 160.205 171.633 161.989 169.456 161.981C167.262 161.981 165.541 160.236 165.541 158.022Z"
          fill="#7545FF"
        />
        <path
          d="M158.302 128.709C156.132 128.709 154.307 126.893 154.331 124.75C154.355 122.575 156.148 120.807 158.326 120.815C160.503 120.823 162.312 122.623 162.296 124.774C162.272 126.94 160.479 128.709 158.302 128.709Z"
          fill="#7545FF"
        />
        <path
          d="M158.31 165.207C160.519 165.207 162.304 166.967 162.288 169.134C162.28 171.301 160.479 173.077 158.31 173.077C156.132 173.077 154.323 171.277 154.331 169.134C154.339 166.952 156.108 165.207 158.31 165.207Z"
          fill="#7545FF"
        />
        <path
          d="M165.541 146.981C165.525 144.742 167.206 143.045 169.448 143.03C171.657 143.022 173.434 144.75 173.458 146.917C173.474 149.052 171.641 150.908 169.512 150.908C167.31 150.916 165.557 149.179 165.541 146.981Z"
          fill="#7545FF"
        />
        <path
          d="M151.065 169.126C151.065 171.349 149.368 173.061 147.151 173.061C144.965 173.061 143.196 171.309 143.196 169.134C143.196 166.944 144.933 165.215 147.143 165.207C149.368 165.207 151.065 166.896 151.065 169.126Z"
          fill="#7545FF"
        />
        <path
          d="M151.066 124.804C151.058 127.003 149.296 128.731 147.103 128.691C144.894 128.651 143.173 126.891 143.197 124.716C143.229 122.526 144.998 120.805 147.183 120.829C149.377 120.853 151.074 122.59 151.066 124.804Z"
          fill="#7545FF"
        />
        <path
          d="M139.913 169.102C139.929 171.285 138.176 173.053 135.991 173.053C133.83 173.061 132.02 171.253 132.028 169.11C132.036 166.992 133.789 165.247 135.935 165.223C138.144 165.199 139.897 166.904 139.913 169.102Z"
          fill="#7545FF"
        />
        <path
          d="M139.914 124.805C139.897 126.996 138.128 128.708 135.919 128.676C133.774 128.645 132.044 126.908 132.028 124.773C132.012 122.623 133.822 120.838 136.007 120.854C138.2 120.862 139.93 122.607 139.914 124.805Z"
          fill="#7545FF"
        />
        <path
          d="M128.732 158.006C128.74 160.205 126.994 161.957 124.809 161.949C122.656 161.941 120.918 160.221 120.902 158.078C120.886 155.887 122.632 154.143 124.833 154.151C127.01 154.151 128.724 155.847 128.732 158.006Z"
          fill="#7545FF"
        />
        <path
          d="M124.769 143.07C126.97 143.054 128.699 144.703 128.731 146.853C128.763 149.06 127.074 150.812 124.873 150.86C122.72 150.908 120.902 149.124 120.902 146.949C120.902 144.782 122.599 143.086 124.769 143.07Z"
          fill="#7545FF"
        />
        <path
          d="M128.731 135.885C128.723 138.06 127.034 139.725 124.841 139.733C122.615 139.741 120.894 138.028 120.902 135.821C120.918 133.671 122.631 131.966 124.801 131.958C127.018 131.95 128.739 133.671 128.731 135.885Z"
          fill="#7545FF"
        />
        <path
          d="M158.302 161.63C156.268 161.63 154.635 160.005 154.659 158.014C154.683 156.078 156.309 154.445 158.27 154.413C160.239 154.381 161.944 156.046 161.96 158.022C161.968 160.013 160.335 161.63 158.302 161.63Z"
          fill="#7545FF"
        />
        <path
          d="M154.659 135.813C154.691 133.845 156.421 132.172 158.374 132.22C160.367 132.268 162.008 133.973 161.952 135.94C161.896 137.892 160.247 139.477 158.286 139.469C156.292 139.461 154.635 137.788 154.659 135.813Z"
          fill="#7545FF"
        />
        <path
          d="M150.762 135.837C150.778 137.812 149.193 139.43 147.216 139.461C145.198 139.493 143.541 137.876 143.533 135.877C143.533 133.901 145.102 132.284 147.08 132.236C149.081 132.181 150.746 133.822 150.762 135.837Z"
          fill="#7545FF"
        />
        <path
          d="M147.112 161.622C145.11 161.607 143.533 160.029 143.533 158.046C143.533 156.023 145.174 154.406 147.184 154.429C149.217 154.461 150.802 156.094 150.762 158.118C150.714 160.117 149.129 161.638 147.112 161.622Z"
          fill="#7545FF"
        />
        <path
          d="M132.382 135.837C132.398 133.878 134.031 132.245 135.984 132.245C137.946 132.245 139.595 133.878 139.603 135.821C139.619 137.821 137.954 139.47 135.944 139.446C133.967 139.422 132.366 137.805 132.382 135.837Z"
          fill="#7545FF"
        />
        <path
          d="M132.382 146.941C132.382 144.958 133.975 143.381 135.968 143.365C137.985 143.349 139.627 144.99 139.603 146.997C139.579 148.949 137.929 150.55 135.96 150.534C133.991 150.526 132.374 148.901 132.382 146.941Z"
          fill="#7545FF"
        />
        <path
          d="M135.96 161.607C133.967 161.599 132.382 160.022 132.382 158.038C132.382 156.071 133.983 154.461 135.96 154.446C137.953 154.422 139.635 156.11 139.594 158.086C139.554 160.077 137.969 161.615 135.96 161.607Z"
          fill="#7545FF"
        />
        <path
          d="M161.601 146.95C161.593 148.782 160.152 150.216 158.311 150.208C156.469 150.208 155.029 148.766 155.021 146.942C155.021 145.133 156.493 143.66 158.311 143.668C160.136 143.668 161.601 145.141 161.601 146.95Z"
          fill="#7545FF"
        />
        <path
          d="M143.891 146.949C143.891 145.109 145.316 143.675 147.141 143.675C148.958 143.675 150.423 145.125 150.431 146.941C150.439 148.757 148.99 150.199 147.157 150.199C145.3 150.207 143.891 148.797 143.891 146.949Z"
          fill="#7545FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_789">
          <rect width="600" height="294" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoPurpleFull;
