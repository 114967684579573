import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const slaPlugin = createPlugin({
  id: 'sla',
  routes: {
    root: rootRouteRef,
  },
});

export const SlaPage = slaPlugin.provide(
  createRoutableExtension({
    name: 'SlaPage',
    component: () => import('./components/SlaComponent').then(m => m.SlaPage),
    mountPoint: rootRouteRef,
  }),
);
