import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const deploymentsPlugin = createPlugin({
  id: 'deployments',
  routes: {
    root: rootRouteRef,
  },
});

export const DeploymentsPage = deploymentsPlugin.provide(
  createRoutableExtension({
    name: 'DeploymentsPage',
    component: () =>
      import('./components/DeploymentsComponent').then(m => m.DeploymentsComponent),
    mountPoint: rootRouteRef,
  }),
);
