import React from 'react';

const LogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M30.0373 13.9354C29.2255 13.9329 28.5751 13.2849 28.5801 12.4782C28.5826 11.6739 29.2432 11.0184 30.0449 11.0235C30.8541 11.026 31.5046 11.6815 31.497 12.4883C31.4919 13.3026 30.8541 13.9354 30.0373 13.9354Z"
        fill="white"
      />
      <path
        d="M24.4608 26.55C24.4557 27.3441 23.8104 27.9795 23.0112 27.982C22.1943 27.9845 21.5389 27.3189 21.5489 26.4945C21.559 25.7079 22.217 25.0625 23.0061 25.0625C23.8305 25.0625 24.4684 25.7155 24.4608 26.55Z"
        fill="white"
      />
      <path
        d="M30.0373 17.4457C29.2255 17.4407 28.5801 16.7953 28.5801 15.986C28.5801 15.1842 29.2431 14.5287 30.0473 14.5313C30.8516 14.5338 31.507 15.1918 31.4995 15.9986C31.4944 16.8104 30.8516 17.4482 30.0373 17.4457Z"
        fill="white"
      />
      <path
        d="M19.4954 0.500007C20.3123 0.502529 20.9526 1.14038 20.9501 1.95472C20.9476 2.75897 20.2845 3.41951 19.4879 3.41699C18.6887 3.41447 18.0307 2.7514 18.0332 1.94715C18.0357 1.13282 18.6761 0.497486 19.4954 0.500007Z"
        fill="white"
      />
      <path
        d="M27.9791 23.0427C27.9665 23.8369 27.3136 24.4823 26.527 24.4798C25.7152 24.4773 25.0345 23.7865 25.0572 22.9898C25.0799 22.1881 25.7606 21.5326 26.5497 21.5553C27.3665 21.5779 27.9943 22.2309 27.9791 23.0427Z"
        fill="white"
      />
      <path
        d="M24.4607 5.46252C24.4607 6.27685 23.8178 6.92479 23.0085 6.92479C22.2043 6.92479 21.5488 6.26929 21.5488 5.46252C21.5463 4.65827 22.1993 4.00781 23.0085 4.00781C23.8304 4.00781 24.4607 4.64062 24.4607 5.46252Z"
        fill="white"
      />
      <path
        d="M30.0264 20.9483C29.2121 20.9433 28.5667 20.2903 28.5793 19.4835C28.5919 18.6793 29.2575 18.0238 30.0541 18.0313C30.8558 18.0389 31.5088 18.7095 31.4987 19.5112C31.4861 20.3306 30.8483 20.9533 30.0264 20.9483Z"
        fill="white"
      />
      <path
        d="M27.9796 8.9653C27.9821 9.77963 27.3392 10.4276 26.53 10.4276C25.7232 10.4276 25.0627 9.77711 25.0576 8.97538C25.0526 8.18122 25.7156 7.51815 26.5199 7.51563C27.3367 7.51311 27.9771 8.15096 27.9796 8.9653Z"
        fill="white"
      />
      <path
        d="M19.4935 28.5859C20.3078 28.5859 20.9532 29.2288 20.9507 30.0381C20.9507 30.8399 20.2876 31.5029 19.491 31.5029C18.6918 31.5029 18.0312 30.8373 18.0312 30.0381C18.0363 29.2238 18.6792 28.5834 19.4935 28.5859Z"
        fill="white"
      />
      <path
        d="M14.5293 30.023C14.5318 29.2087 15.1697 28.5809 15.9915 28.586C16.8033 28.591 17.4412 29.2364 17.4361 30.0457C17.4286 30.8474 16.763 31.5055 15.9688 31.4954C15.1621 31.4878 14.5268 30.8348 14.5293 30.023Z"
        fill="white"
      />
      <path
        d="M14.5293 1.93234C14.5344 1.12052 15.1747 0.500317 15.9991 0.507881C16.8109 0.512923 17.4437 1.16086 17.4336 1.9752C17.4235 2.77188 16.7454 3.43495 15.9563 3.4173C15.1546 3.39965 14.5243 2.74415 14.5293 1.93234Z"
        fill="white"
      />
      <path
        d="M11.0147 1.95748C11.0096 1.14062 11.6449 0.507812 12.4643 0.507812C13.2736 0.507812 13.9265 1.15827 13.9215 1.96C13.9164 2.74912 13.2383 3.41723 12.4467 3.40967C11.6601 3.4021 11.0172 2.75164 11.0147 1.95748Z"
        fill="white"
      />
      <path
        d="M12.4593 28.5859C13.2635 28.5834 13.924 29.2389 13.9215 30.0356C13.919 30.8247 13.2434 31.4928 12.4517 31.4903C11.6702 31.4853 11.0223 30.8348 11.0147 30.0432C11.0072 29.2263 11.6399 28.5885 12.4593 28.5859Z"
        fill="white"
      />
      <path
        d="M10.3858 5.46536C10.3807 6.27213 9.74542 6.90746 8.94624 6.90494C8.12941 6.8999 7.50671 6.26205 7.51175 5.43259C7.51679 4.62834 8.1521 4.00813 8.96389 4.01569C9.76811 4.02326 10.3908 4.65607 10.3858 5.46536Z"
        fill="white"
      />
      <path
        d="M4.00049 8.92845C4.02066 8.12924 4.6711 7.51156 5.47532 7.53173C6.27198 7.5519 6.88713 8.2074 6.86696 9.01165C6.84679 9.81086 6.18879 10.4361 5.39213 10.4084C4.58538 10.3806 3.98032 9.73774 4.00049 8.92845Z"
        fill="white"
      />
      <path
        d="M8.95883 25.086C9.76809 25.091 10.3883 25.7213 10.3858 26.5306C10.3807 27.3576 9.74792 27.9752 8.92101 27.9626C8.10922 27.95 7.50921 27.3298 7.51173 26.5054C7.51677 25.7012 8.14956 25.0784 8.95883 25.086Z"
        fill="white"
      />
      <path
        d="M5.41949 21.586C6.23128 21.5784 6.85903 22.1911 6.86659 23.0004C6.87668 23.8021 6.25397 24.4374 5.44722 24.4475C4.643 24.4576 4.01021 23.8349 4.00012 23.0281C3.99004 22.2188 4.6077 21.591 5.41949 21.586Z"
        fill="white"
      />
      <path
        d="M3.33873 12.4941C3.33621 13.2807 2.71098 13.911 1.92189 13.916C1.12271 13.9211 0.5 13.2908 0.5 12.4739C0.5 11.6772 1.11766 11.0545 1.90928 11.0469C2.69082 11.0394 3.33873 11.6974 3.33873 12.4941Z"
        fill="white"
      />
      <path
        d="M3.33861 16.0122C3.33357 16.7988 2.70582 17.4215 1.9142 17.4215C1.10493 17.419 0.499872 16.7988 0.504914 15.9769C0.509956 15.1802 1.13266 14.5575 1.92176 14.5625C2.7159 14.5651 3.34113 15.2054 3.33861 16.0122Z"
        fill="white"
      />
      <path
        d="M3.33875 19.515C3.33622 20.3117 2.71856 20.9243 1.91938 20.9243C1.10003 20.9243 0.497487 20.3142 0.500008 19.4898C0.502529 18.6931 1.12776 18.0653 1.91181 18.0703C2.71604 18.0754 3.34127 18.7082 3.33875 19.515Z"
        fill="white"
      />
      <path
        d="M18.1416 26.5154C18.1492 25.7717 18.7517 25.1691 19.4879 25.1641C20.2367 25.1615 20.8468 25.7792 20.8417 26.5356C20.8367 27.2945 20.224 27.892 19.4652 27.8794C18.724 27.8668 18.1366 27.2617 18.1416 26.5154Z"
        fill="white"
      />
      <path
        d="M25.1709 19.5151C25.1659 18.7739 25.7609 18.1612 26.4995 18.1486C27.2508 18.136 27.8735 18.7386 27.8836 19.4924C27.8937 20.2336 27.2685 20.8589 26.5197 20.8589C25.781 20.8589 25.176 20.2563 25.1709 19.5151Z"
        fill="white"
      />
      <path
        d="M26.5309 14.6406C27.2771 14.6432 27.8796 15.2381 27.8847 15.9794C27.8897 16.7231 27.2645 17.3509 26.5208 17.3458C25.7695 17.3433 25.1619 16.7231 25.172 15.9693C25.1796 15.2281 25.7821 14.6381 26.5309 14.6406Z"
        fill="white"
      />
      <path
        d="M24.3516 23.0303C24.344 23.7816 23.749 24.3741 23.0053 24.3716C22.254 24.369 21.6591 23.7589 21.6641 22.9925C21.6666 22.2513 22.2717 21.6512 23.0154 21.6563C23.7717 21.6588 24.3591 22.2614 24.3516 23.0303Z"
        fill="white"
      />
      <path
        d="M26.5273 13.8279C25.7609 13.8279 25.1659 13.2304 25.1709 12.469C25.176 11.7253 25.776 11.1328 26.5222 11.1328C27.276 11.1328 27.8912 11.748 27.8836 12.4968C27.876 13.238 27.276 13.8279 26.5273 13.8279Z"
        fill="white"
      />
      <path
        d="M20.8447 5.44787C20.8497 6.19917 20.2648 6.79921 19.5211 6.80677C18.7522 6.81686 18.1496 6.23195 18.1446 5.47056C18.137 4.71421 18.7497 4.09905 19.4984 4.10157C20.2396 4.10661 20.8396 4.70665 20.8447 5.44787Z"
        fill="white"
      />
      <path
        d="M24.3511 8.98151C24.3461 9.73786 23.7688 10.3152 23.0099 10.3152C22.241 10.3152 21.6586 9.72778 21.6611 8.9563C21.6637 8.19239 22.2612 7.61505 23.0351 7.62513C23.7889 7.63269 24.3562 8.2176 24.3511 8.98151Z"
        fill="white"
      />
      <path
        d="M17.3211 26.5078C17.3237 27.2692 16.7287 27.8718 15.9749 27.8718C15.2286 27.8718 14.6412 27.2844 14.6387 26.5331C14.6337 25.7641 15.2261 25.1615 15.9824 25.1641C16.7211 25.1666 17.3186 25.7666 17.3211 26.5078Z"
        fill="white"
      />
      <path
        d="M13.8118 26.5132C13.8169 27.2519 13.2219 27.862 12.4832 27.8721C11.7294 27.8822 11.1117 27.2746 11.1143 26.5232C11.1168 25.7845 11.7193 25.1795 12.458 25.1719C13.1891 25.1669 13.8043 25.777 13.8118 26.5132Z"
        fill="white"
      />
      <path
        d="M17.3217 5.45839C17.3217 6.22482 16.7317 6.81225 15.9653 6.80721C15.214 6.80217 14.6392 6.21978 14.6367 5.46343C14.6367 4.69952 15.2367 4.09696 15.9905 4.10957C16.7393 4.11965 17.3242 4.7096 17.3217 5.45839Z"
        fill="white"
      />
      <path
        d="M12.458 6.80481C11.7143 6.80481 11.1117 6.20478 11.1143 5.46608C11.1143 4.72738 11.7118 4.12482 12.453 4.11726C13.2017 4.10969 13.8169 4.72486 13.8118 5.47616C13.8043 6.21486 13.2042 6.80481 12.458 6.80481Z"
        fill="white"
      />
      <path
        d="M7.61622 23.0078C7.6137 22.2591 8.20363 21.6666 8.94987 21.6641C9.68603 21.6641 10.2886 22.2666 10.2911 23.0078C10.2911 23.7465 9.68603 24.3516 8.94987 24.3541C8.20111 24.3567 7.61622 23.7642 7.61622 23.0078Z"
        fill="white"
      />
      <path
        d="M8.94987 7.64063C9.70367 7.64315 10.2911 8.23058 10.2886 8.98189C10.2835 9.72563 9.68098 10.3131 8.9297 10.308C8.1885 10.303 7.61874 9.72311 7.61622 8.97685C7.6137 8.21294 8.1885 7.63811 8.94987 7.64063Z"
        fill="white"
      />
      <path
        d="M5.42825 20.8264C4.66941 20.8239 4.07695 20.2289 4.09208 19.4852C4.10721 18.7591 4.70975 18.1641 5.4333 18.1641C6.16441 18.1641 6.78712 18.7969 6.77199 19.5204C6.75686 20.2617 6.17197 20.8264 5.42825 20.8264Z"
        fill="white"
      />
      <path
        d="M5.42817 13.8057C4.67688 13.8057 4.07939 13.2032 4.09199 12.462C4.1046 11.7333 4.70209 11.1484 5.43321 11.1484C6.17441 11.1484 6.78451 11.7636 6.7719 12.4973C6.7593 13.2284 6.16684 13.8057 5.42817 13.8057Z"
        fill="white"
      />
      <path
        d="M5.4441 17.3161C4.69534 17.3211 4.08776 16.7211 4.0928 15.9849C4.09785 15.2538 4.6903 14.6613 5.42141 14.6563C6.16009 14.6512 6.77523 15.2563 6.77271 15.9925C6.77019 16.7211 6.17774 17.3111 5.4441 17.3161Z"
        fill="white"
      />
      <path
        d="M23.0177 11.2266C23.7034 11.2342 24.2656 11.804 24.2631 12.4872C24.2606 13.1705 23.6883 13.7327 23.0026 13.7302C22.2992 13.7276 21.7571 13.1604 21.7697 12.4418C21.7824 11.7636 22.342 11.2191 23.0177 11.2266Z"
        fill="white"
      />
      <path
        d="M21.7715 19.4982C21.774 18.7948 22.3135 18.2553 23.0144 18.2578C23.7052 18.2603 24.2674 18.8226 24.2648 19.5083C24.2623 20.189 23.69 20.7538 23.0043 20.7513C22.3135 20.7513 21.7715 20.1991 21.7715 19.4982Z"
        fill="white"
      />
      <path
        d="M19.4917 10.2172C18.8085 10.2172 18.2337 9.6424 18.2413 8.96421C18.2488 8.27594 18.8136 7.71624 19.4993 7.71876C20.185 7.72128 20.7548 8.29106 20.7498 8.97178C20.7422 9.65753 20.1775 10.2172 19.4917 10.2172Z"
        fill="white"
      />
      <path
        d="M19.4942 21.7734C20.19 21.7734 20.7522 22.3306 20.7472 23.0164C20.7446 23.7021 20.1774 24.2643 19.4942 24.2643C18.8085 24.2643 18.2387 23.6946 18.2412 23.0164C18.2437 22.3256 18.8009 21.7734 19.4942 21.7734Z"
        fill="white"
      />
      <path
        d="M21.7715 16.0005C21.7665 15.2921 22.2959 14.7551 23.0018 14.75C23.6976 14.7475 24.2573 15.2946 24.2649 15.9803C24.2699 16.656 23.6926 17.2434 23.022 17.2434C22.3287 17.246 21.7766 16.6963 21.7715 16.0005Z"
        fill="white"
      />
      <path
        d="M17.2126 23.0138C17.2126 23.7173 16.6781 24.2593 15.9798 24.2593C15.2915 24.2593 14.7344 23.7046 14.7344 23.0164C14.7344 22.3231 15.2814 21.776 15.9773 21.7734C16.6781 21.7734 17.2126 22.3079 17.2126 23.0138Z"
        fill="white"
      />
      <path
        d="M17.2127 8.9847C17.2102 9.68054 16.6555 10.2276 15.9647 10.215C15.2689 10.2024 14.7269 9.64524 14.7345 8.95696C14.7445 8.26365 15.3017 7.71908 15.99 7.72664C16.6807 7.7342 17.2152 8.28382 17.2127 8.9847Z"
        fill="white"
      />
      <path
        d="M13.7001 23.0013C13.7051 23.6921 13.153 24.2518 12.4647 24.2518C11.784 24.2543 11.2143 23.682 11.2168 23.0038C11.2193 22.3332 11.7714 21.7811 12.4471 21.7735C13.1429 21.766 13.695 22.3055 13.7001 23.0013Z"
        fill="white"
      />
      <path
        d="M13.7001 8.9849C13.695 9.67822 13.1379 10.2203 12.4421 10.2102C11.7664 10.2001 11.2219 9.65049 11.2168 8.97482C11.2118 8.29411 11.7816 7.72937 12.4698 7.73441C13.1606 7.73693 13.7051 8.28906 13.7001 8.9849Z"
        fill="white"
      />
      <path
        d="M10.1785 19.4937C10.1811 20.1895 9.63147 20.7442 8.94321 20.7417C8.26504 20.7391 7.71797 20.1946 7.71293 19.5164C7.70788 18.8231 8.25748 18.2709 8.95077 18.2734C9.63651 18.2734 10.176 18.8105 10.1785 19.4937Z"
        fill="white"
      />
      <path
        d="M8.93057 14.7657C9.62387 14.7606 10.1684 15.2825 10.1785 15.9632C10.1886 16.6616 9.65664 17.2162 8.96335 17.2314C8.28518 17.2465 7.71289 16.6817 7.71289 15.9935C7.71289 15.3077 8.24736 14.7707 8.93057 14.7657Z"
        fill="white"
      />
      <path
        d="M10.1785 12.4929C10.176 13.1812 9.64405 13.7081 8.95327 13.7107C8.25241 13.7132 7.71038 13.1711 7.7129 12.4728C7.71794 11.7921 8.25745 11.2525 8.94066 11.25C9.639 11.2475 10.181 11.7921 10.1785 12.4929Z"
        fill="white"
      />
      <path
        d="M19.4919 20.6359C18.8515 20.6359 18.3372 20.1216 18.3448 19.4913C18.3524 18.8786 18.8642 18.3618 19.4818 18.3517C20.102 18.3416 20.639 18.8685 20.644 19.4938C20.6466 20.1241 20.1323 20.6359 19.4919 20.6359Z"
        fill="white"
      />
      <path
        d="M18.3448 12.4655C18.3549 11.8428 18.8994 11.3133 19.5146 11.3284C20.1423 11.3436 20.6592 11.8831 20.6415 12.5058C20.6239 13.1235 20.1045 13.6252 19.4869 13.6227C18.8591 13.6202 18.3372 13.0907 18.3448 12.4655Z"
        fill="white"
      />
      <path
        d="M17.1174 12.4759C17.1224 13.1012 16.6232 13.613 16.0005 13.6231C15.3652 13.6331 14.8433 13.1214 14.8408 12.4885C14.8408 11.8633 15.335 11.3515 15.9577 11.3364C16.5879 11.3187 17.1123 11.8381 17.1174 12.4759Z"
        fill="white"
      />
      <path
        d="M15.9677 20.6361C15.3375 20.631 14.8408 20.1318 14.8408 19.5041C14.8408 18.8637 15.3576 18.3519 15.9904 18.3595C16.6308 18.3695 17.13 18.8864 17.1174 19.5268C17.1022 20.1596 16.6031 20.6411 15.9677 20.6361Z"
        fill="white"
      />
      <path
        d="M11.3282 12.473C11.3332 11.8528 11.8475 11.3359 12.4626 11.3359C13.0803 11.3359 13.5997 11.8528 13.6022 12.4679C13.6072 13.1007 13.0828 13.6226 12.45 13.6151C11.8273 13.6075 11.3231 13.0957 11.3282 12.473Z"
        fill="white"
      />
      <path
        d="M11.3281 15.9914C11.3281 15.3636 11.8298 14.8645 12.4576 14.8594C13.0929 14.8544 13.6097 15.3737 13.6021 16.0091C13.5946 16.6267 13.0752 17.1335 12.4551 17.1284C11.8349 17.1259 11.3256 16.6116 11.3281 15.9914Z"
        fill="white"
      />
      <path
        d="M12.455 20.6338C11.8273 20.6313 11.3281 20.1321 11.3281 19.5043C11.3281 18.8816 11.8323 18.3723 12.455 18.3673C13.0828 18.3597 13.6122 18.8942 13.5996 19.5194C13.587 20.1497 13.0878 20.6363 12.455 20.6338Z"
        fill="white"
      />
      <path
        d="M20.5303 15.9919C20.5278 16.5717 20.074 17.0255 19.4942 17.023C18.9143 17.023 18.4605 16.5667 18.458 15.9893C18.458 15.417 18.9219 14.9506 19.4942 14.9531C20.069 14.9531 20.5303 15.4195 20.5303 15.9919Z"
        fill="white"
      />
      <path
        d="M14.9531 15.9893C14.9531 15.4069 15.4019 14.9531 15.9767 14.9531C16.549 14.9531 17.0103 15.412 17.0128 15.9868C17.0154 16.5616 16.559 17.0179 15.9817 17.0179C15.3968 17.0205 14.9531 16.5742 14.9531 15.9893Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoIcon;
