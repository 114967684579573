import 'inter-ui/inter.css';
import { createTheme, darkTheme, genPageTheme, shapes } from '@backstage/theme';

export const deepcTheme = createTheme({
  fontFamily: 'Inter',
  palette: {
    ...darkTheme.palette,
    type: 'dark',
    common: {
      black: '#212132',
      white: '#A6A6AD',
    },
    primary: {
      light: '#A6A6AD',
      dark: '#A6A6AD',
      main: '#A6A6AD',
      contrastText: '#fff',
    },
    secondary: {
      light: '#A6A6AD',
      dark: '#A6A6AD',
      main: '#A6A6AD',
      contrastText: '#fff',
    },
    error: {
      main: '#212132',
    },
    warning: {
      main: '#FF0000',
    },
    info: {
      light: '#fff',
      dark: '#fff000',
      main: '#fff',
      contrastText: '#fff',
    },
    success: {
      main: '#FF0000',
    },
    background: {
      default: '#212132',
      paper: '#2B2B41',
    },
    errorBackground: '#212132',
    warningBackground: '#8f5e15',
    infoBackground: '#343b58',
    navigation: {
      background: '#212132',
      indicator: '#8f5e15',
      color: '#d5d6db',
      selectedColor: '#A6A6AD',
    },
  },
  defaultPageTheme: 'tool',
  pageTheme: {
    home: genPageTheme({
      colors: ['#212132', '#343b58'],
      shape: shapes.wave,
      options: { fontColor: '#fff' },
    }),
    documentation: genPageTheme({
      colors: ['#212132', '#343b58'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({
      colors: ['#212132', '#343b58'],
      shape: shapes.round,
      options: { fontColor: '#fff' },
    }),
    service: genPageTheme({
      colors: ['#212132', '#343b58'],
      shape: shapes.wave,
      options: { fontColor: '#fff' },
    }),
    website: genPageTheme({
      colors: ['#212132', '#343b58'],
      shape: shapes.wave,
      options: { fontColor: '#fff' },
    }),
    library: genPageTheme({
      colors: ['#212132', '#343b58'],
      shape: shapes.wave,
      options: { fontColor: '#fff' },
    }),
    other: genPageTheme({
      colors: ['#212132', '#343b58'],
      shape: shapes.wave,
      options: { fontColor: '#fff' },
    }),
    app: genPageTheme({
      colors: ['#212132', '#343b58'],
      shape: shapes.wave,
      options: { fontColor: '#fff' },
    }),
    apis: genPageTheme({
      colors: ['#212132', '#343b58'],
      shape: shapes.wave,
      options: { fontColor: '#fff' },
    }),
  },
});
