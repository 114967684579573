import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';

const rings = new Array<RadarRing>();
rings.push({ id: 'adopt', name: 'ADOPT', color: '#5BA300' });
rings.push({ id: 'trial', name: 'TRIAL', color: '#009EB0' });
rings.push({ id: 'assess', name: 'ASSESS', color: '#C7BA00' });
rings.push({ id: 'hold', name: 'HOLD', color: '#E09B96' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'infrastructure', name: 'Infrastructure' });
quadrants.push({ id: 'frameworks', name: 'Frameworks' });
quadrants.push({ id: 'languages', name: 'Languages' });
quadrants.push({ id: 'process', name: 'Process' });

const entries = new Array<RadarEntry>();
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-08-01'),
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
    },
  ],
  key: 'typescript',
  id: 'typescript',
  title: 'TypeScript',
  quadrant: 'languages',
  links: [
    {
      url: 'https://www.typescriptlang.org/',
      title: 'TypeScript',
    },
  ],
  description:
    'TypeScript is a strongly typed programming language that builds on JavaScript, giving you better tooling at any scale.\n\n```ts\nconst x = "3";\n```\n',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2021-08-01'),
    },
  ],
  key: 'rust',
  id: 'rust',
  title: 'Rust',
  quadrant: 'languages',
  links: [
    {
      url: 'https://www.rust-lang.org/',
      title: 'Rust',
    },
  ],
  description:
    'A language empowering everyone to build reliable and efficient software. .\n\n```rust\nlet first_number = first_number_str.parse::<i32>()?;\n```\n',
});
entries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'adopt',
      date: new Date('2020-08-01'),
    },
  ],
  links: [
    {
      url: 'https://docs.rs/axum/latest/axum/',
      title: 'Learn more',
    },
  ],
  key: 'axum',
  id: 'axum',
  title: 'Axum',
  quadrant: 'frameworks',
  description:
    'axum is a web application framework that focuses on ergonomics and modularity.',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  links: [
    {
      url: 'https://reactjs.org/',
      title: 'Learn more',
    },
  ],
  key: 'react',
  id: 'react',
  title: 'React',
  quadrant: 'frameworks',
  description: 'The library for web and native user interfaces',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  links: [
    {
      url: 'https://vuejs.org/',
      title: 'Learn more',
    },
  ],
  key: 'vuejs',
  id: 'vuejs',
  title: 'Vuejs',
  quadrant: 'frameworks',
  description:
    'An approachable, performant and versatile framework for building web user interfaces. ',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  links: [
    {
      url: 'https://gitlab.com/deepc/engineering/deepc-os/lib/deepc-rs',
      title: 'Learn more',
    },
  ],
  key: 'deepcrs',
  id: 'deepcrs',
  title: 'Deepc RS',
  quadrant: 'frameworks',
  description:
    'An internal productivity library of reusable components for building deepc products and services',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  links: [
    {
      url: 'https://opentelemetry.io/docs/what-is-opentelemetry/',
      title: 'Learn more',
    },
  ],
  key: 'otel',
  id: 'otel',
  title: 'Open Telemetry',
  quadrant: 'frameworks',
  description:
    'OpenTelemetry is an Observability framework and toolkit designed to create and manage telemetry data such as traces, metrics, and logs. ',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  key: 'code-reviews',
  id: 'code-reviews',
  title: 'Code Reviews',
  quadrant: 'process',
  description: 'Code is reviewed before being deployed to production.',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2020-08-06'),
    },
  ],
  key: 'pair-programming',
  id: 'pair-programming',
  title: 'Pair Programming',
  quadrant: 'process',
  description:
    'Pair programming is an Agile software development technique originating from Extreme programming (XP) in which two developers team together on one computer',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  key: 'continuous-delivery',
  id: 'continuous-delivery',
  title: 'Continous Delivery',
  quadrant: 'process',
  description:
    'Continuous Delivery focuses on ensuring software is always release-ready with manual approval',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2020-08-06'),
    },
  ],
  key: 'continuous-deployment',
  id: 'continuous-deployment',
  title: 'Continous Deployment',
  quadrant: 'process',
  description:
    'Continuous Deployment automates the release process, deploying changes to production automatically once tests pass',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'adopt',
      date: new Date('2020-08-06'),
    },
  ],
  key: 'docs-like-code',
  id: 'docs-like-code',
  title: 'Docs-like-code',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      ringId: 'trial',
      date: new Date('2020-08-06'),
    },
  ],
  key: 'force-push',
  id: 'force-push',
  title: 'Force push to master',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06'),
      description: 'long description',
    },
  ],
  links: [
    {
      url: 'https://github.com',
      title: 'Learn more',
    },
  ],
  key: 'gitlab',
  id: 'gitlab',
  title: 'Gitlab',
  quadrant: 'infrastructure',
  description:
    'GitLab is an open-core company that operates GitLab, a DevOps software package which can develop, secure, and operate software',
});
entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06'),
      description: 'long description',
    },
    {
      ringId: 'trial',
      date: new Date('2020-07-05'),
      description: 'long description',
    },
  ],
  links: [
    {
      url: 'https://aws.com',
      title: 'Learn more',
    },
  ],
  key: 'aws',
  id: 'aws',
  title: 'AWS',
  quadrant: 'infrastructure',
  description:
    'AWS has the services to help you build sophisticated applications with increased flexibility, scalability and reliability',
});
entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06'),
      description: 'long description',
    },
  ],
  links: [
    {
      url: 'https://cloud.google.com',
      title: 'Learn more',
    },
  ],
  key: 'gcloud',
  id: 'gcloud',
  title: 'Google Cloud',
  quadrant: 'infrastructure',
  description:
    'Build apps fast, leverage generative AI, and analyze data in seconds—all with Google-grade security.',
});
entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2020-08-06'),
      description: 'long description',
    },
  ],
  links: [
    {
      url: 'https://kubernetes.io/',
      title: 'Learn more',
    },
  ],
  key: 'k8',
  id: 'k8',
  title: 'Kubernetes',
  quadrant: 'infrastructure',
  description:
    'Kubernetes is an open-source container orchestration system for automating software deployment, scaling, and management.',
});
entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2021-08-06'),
    },
    {
      ringId: 'trial',
      date: new Date('2022-07-05'),
    },
  ],
  links: [
    {
      url: 'https://knative.dev/',
      title: 'Learn more',
    },
  ],
  key: 'kn',
  id: 'kn',
  title: 'Knative',
  quadrant: 'infrastructure',
  description:
    'Knative is an Open-Source Enterprise-level solution to build Serverless and Event Driven Applications',
});
entries.push({
  timeline: [
    {
      ringId: 'adopt',
      date: new Date('2021-08-06'),
    },
  ],
  links: [
    {
      url: 'https://www.postgresql.org/',
      title: 'Learn more',
    },
  ],
  key: 'postgres',
  id: 'postgres',
  title: 'Postgres',
  quadrant: 'infrastructure',
  description:
    'PostgreSQL is a powerful, open source object-relational database system with over 35 years of active development that has earned it a strong reputation for reliability, feature robustness, and performance. ',
});

export const mock: TechRadarLoaderResponse = {
  entries,
  quadrants,
  rings,
};

const visionRings = new Array<RadarRing>();
visionRings.push({ id: 'adopt', name: 'ADOPT', color: '#5BA300' });
visionRings.push({ id: 'trial', name: 'TRIAL', color: '#009EB0' });
visionRings.push({ id: 'assess', name: 'ASSESS', color: '#C7BA00' });
visionRings.push({ id: 'hold', name: 'HOLD', color: '#E09B96' });

const visionQuadrants = new Array<RadarQuadrant>();
visionQuadrants.push({ id: 'product', name: 'Product Roadmaps' });
visionQuadrants.push({ id: 'technical', name: 'Technical' });
visionQuadrants.push({ id: 'culture', name: 'Culture' });
visionQuadrants.push({ id: 'leadership', name: 'Leadership' });

const visionEntries = new Array<RadarEntry>();

visionEntries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'trial',
      date: new Date('2024-01-18'),
      description: 'draft accepted',
    },
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-01-15'),
      description: 'initial draft',
    },
  ],
  key: 'bi-dev-2024',
  id: 'bi-dev-2024',
  title: 'BI development 2024',
  quadrant: 'technical',
  links: [
    {
      url: 'https://docs.google.com/document/d/1XFK0k8VFRPy2a-NcLgcRhwjaGGWGOyHnsjPwhaoVZjM/edit?usp=sharing',
      title: 'BI development 2024',
    },
  ],
  description:
    'In order to fulfil various product, business and tender requirements we need a way to collect data and display it in a way suitable for various users to gain business insights from it. Various dashboards have been presented and used in all-hands, at RSNA and with potential users. This document will briefly outline how we achieved this to date and how we want to move forward this quarter and beyond to achieve our objectives.',
});

visionEntries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-01-22'),
      description: 'initial draft',
    },
  ],
  key: 'arch-dev-2024',
  id: 'arch-dev-2024',
  title: 'Event Data Contract',
  quadrant: 'technical',
  links: [
    {
      url: 'https://docs.google.com/document/d/14u16mbkUzpE0AhnNZKQADAlBP-9FxY8wHLyFoMtmtdU/edit?usp=sharing',
      title: 'Event Data Contract',
    },
  ],
  description:
    'In order to reliably provide data to external systems and ensure continuity of service it is necessary to manage explicit stability guarantees for consumption of the data.',
});

visionEntries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'trial',
      date: new Date('2024-01-15'),
      description: 'draft accepted',
    },
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-01-07'),
      description: 'initial draft',
    },
  ],
  key: 'gate-dev01-2024',
  id: 'gate-dev01-2024',
  title: 'deepcOS Gate Changes - Repurposing AIDA Components',
  quadrant: 'technical',
  links: [
    {
      url: 'https://docs.google.com/document/d/1r0W7S8O6ZiNiFrtnANO0YOpeDjGVRm_MiZ4bD9rvgo8/edit?usp=sharing',
      title: 'deepcOS Gate Changes - Repurposing AIDA Components',
    },
  ],
  description:
    'It is inevitable that during the lifecycle of a product, some major changes are required to ensure upkeep and modernisation. As the requirements from the market continue to evolve and as we continue to obtain new data that either validates or invalidates our original product design assumptions, we must have the courage to face the realities of that feedback and where appropriate make bold design changes. This document outlines changes and consolidation efforts that must be made to ensure a continued health of the deepOS product. In particular this document looks to take advantages of large gains and opportunities delivered by the apps/AIDA team, in particular the reusable DICOM viewer components called ‘Brill’.',
});

visionEntries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-06-19'),
      description: 'initial draft',
    },
  ],
  key: 'tech-dev01-2024',
  id: 'tech-dev01-2024',
  title: 'deepc Engineering Strategy',
  quadrant: 'technical',
  links: [
    {
      url: 'https://docs.google.com/document/d/1UKseJfpHgpblgCT5Q7put7i-wgi0wHg4d0dYRwjbx5c/edit?usp=sharing',
      title: 'deepc Engineering Strategy',
    },
  ],
  description:
    'This document outlines the deepc engineering strategy, include technical tools selection, processes and practices.',
});

visionEntries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-01-07'),
      description: 'initial draft',
    },
  ],
  key: 'deepc-iam01-2024',
  id: 'deepc-iam01-2024',
  title: 'deepcOS user requirements - IAM',
  quadrant: 'technical',
  links: [
    {
      url: 'https://docs.google.com/document/d/1unFtY8DOW83QHRDrmmNPB0dqaSNB2_v_SQ31jd-GYvQ/edit?usp=sharing',
      title: 'deepcOS user requirements - IAM',
    },
  ],
  description:
    'Enterprise customers for deepc are large radiology chains that consist of multiple (large) sites (physical locations), where radiology exams are performed',
});

visionEntries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2023-06-28'),
      description: 'initial draft',
    },
  ],
  key: 'lead-01-2023',
  id: 'lead-01-2023',
  title: 'Value Stream Teams',
  quadrant: 'leadership',
  links: [
    {
      url: 'https://docs.google.com/document/d/1DBNvigmbsW8eitLic9mwS_8RqpRycidwjKho5IKyb9Y/edit?usp=sharing',
      title: 'Value Stream Teams',
    },
  ],
  description:
    'This document proposees organizing our organization by value streams, a strategic model that aligns teams and processes around the core activities that deliver value to our customers. By adopting this approach, we will unlock efficiency, enhance collaboration, and achieve improved outcomes that will elevate our ability to react to external and internal conditions',
});

visionEntries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'adopt',
      date: new Date('2024-02-03'),
      description: 'adopt',
    },
    {
      moved: 1,
      ringId: 'trial',
      date: new Date('2024-02-02'),
      description: 'trial',
    },
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-01-18'),
      description: 'initial draft',
    },
  ],
  key: 'lead-03-2023',
  id: 'lead-03-2023',
  title: 'Commercial Sales Demos - IT Strategy',
  quadrant: 'leadership',
  links: [
    {
      url: 'https://docs.google.com/document/d/1Sr9JBxZevmTKN9NGlSOXQy02BNq-pTEnHrOMgnj1WJ0/edit?usp=sharing',
      title: 'Commercial Sales Demos - IT Strategy',
    },
  ],
  description:
    'There is increasing ad-hoc demand to setup demo environments showcasing either our products in isolation or how our products integrate with partners in our ecosystem. This document outlines some problems, past challenges and solutions to assist leadership in selecting a consistent and strategic approach to enabling our sales teams.',
});

visionEntries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2023-06-26'),
      description: 'initial draft',
    },
  ],
  key: 'lead-02-2023',
  id: 'lead-02-2023',
  title: 'Effective Product Performance',
  quadrant: 'leadership',
  links: [
    {
      url: 'https://docs.google.com/document/d/1JPFdEwGlK_f-T0PpANMoFBQBYUs1JAHOylsN6001QKA/edit?usp=sharing',
      title: 'Effective Product Performance',
    },
  ],
  description:
    'A request to diagnose current communication structures, pathways and leadership.',
});

visionEntries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-01-15'),
      description: 'initial draft',
    },
  ],
  key: 'lead-03-2024',
  id: 'lead-03-2024',
  title: 'Product team focus sharpening 2024',
  quadrant: 'leadership',
  links: [
    {
      url: 'https://docs.google.com/document/d/1j6NLXlut1ayPX6LTWc5cwFta15Wh2yXYupThmo5mmVY/edit?usp=sharing',
      title: 'Product team focus sharpening 2024',
    },
  ],
  description:
    'In order to further build out deepc’s success and to ensure a further scalable product organisation, a refocus of the product team setup is envisioned for 2024. The proposed approach is to refocus the product teams around specific value streams, to further empower the teams by enhancing autonomy and ownership while clarifying accountability. Ideally we move towards a true KPI driven product organisation.',
});

visionEntries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'adopt',
      date: new Date('2024-02-02'),
      description: 'kickoff meeting',
    },
    {
      moved: 1,
      ringId: 'trial',
      date: new Date('2023-12-19'),
      description: 'draft accepted',
    },
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2023-12-10'),
      description: 'initial draft',
    },
  ],
  key: 'culture-01-2023',
  id: 'culture-01-2023',
  title: 'Expert Groups',
  quadrant: 'culture',
  links: [
    {
      url: 'https://docs.google.com/document/d/1YQMgFN6aBO2V9c4-zYOasKb8EDsueOcDu1_XkiUaQWQ/edit?usp=sharing',
      title: 'Expert Groups',
    },
  ],
  description:
    'Value streams are teams with different sets of specialized capabilities, working collectively to deliver, autonomously, an outcome that serves a customer’s needs. Expert groups consist of value stream participants who share, learn, socialize, and develop a specific competency, serving as subject matter experts across the organization.',
});

visionEntries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'trial',
      date: new Date('2024-02-02'),
      description: 'trial',
    },
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-01-15'),
      description: 'initial draft',
    },
  ],
  key: 'culture-02-2023',
  id: 'culture-02-2023',
  title: 'Scaling Thought Leadership - White Papers',
  quadrant: 'culture',
  links: [
    {
      url: 'https://docs.google.com/document/d/19M2cVl7aFuh3_A-u-XYBOF3nlHvOTpATfp4R_1bAQ4Y/edit?usp=sharing',
      title: 'Scaling Thought Leadership - White Papers',
    },
  ],
  description:
    'A whitepaper is a persuasive, authoritative, in-depth report on a specific topic that presents a problem and provides a solution. As the organization grows in complexity, time-zones and ambition, employees at all levels and geographies need nuanced information to understand what is expected of them to enable organizational autonomy, at scale.',
});

visionEntries.push({
  timeline: [
    {
      moved: -1,
      ringId: 'hold',
      date: new Date('2024-02-02'),
      description: 'undecided',
    },
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-01-15'),
      description: 'inital draft',
    },
  ],
  key: 'product-01-2024',
  id: 'product-01-2024',
  title: 'deepcOne Domain Strategy',
  quadrant: 'product',
  links: [
    {
      url: 'https://docs.google.com/document/d/1Nyw8vFIHZohlW1xTH-oq_a6gUHQQiULUrblqHEwHvus/edit?usp=sharing',
      title: 'deepcOne Domain Strategy',
    },
  ],
  description:
    'In order to further build out deepc’s success and to ensure a further scalable product, a cloud product offering is envisioned for 2024. Our main focus of delivering the product so far was by installing in customers infrastructure and seamless integration into existing systems. We want to complement this system with a customer experience outside of their own infrastructure (need for VPN, using internal IP addresses to access deepc product offerings).',
});

visionEntries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'assess',
      date: new Date('2024-02-02'),
      description: 'in review',
    },
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2024-01-15'),
      description: 'created',
    },
  ],
  key: 'product-05-2024',
  id: 'product-05-2024',
  title: 'deepcOS - Deep integration 2.0',
  quadrant: 'product',
  links: [
    {
      url: 'https://docs.google.com/document/d/1lhokBAavDdf0lSLX1GkLBW5CgxiI8h9SSGXnFyF97zQ/edit',
      title: 'deepcOS - Deep integration 2.0',
    },
  ],
  description: 'todo',
});

visionEntries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'assess',
      date: new Date('2024-02-02'),
      description: 'in review',
    },
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2024-01-15'),
      description: 'created',
    },
  ],
  key: 'product-06-2024',
  id: 'product-06-2024',
  title: 'deepcOS - Enterprise segment',
  quadrant: 'product',
  links: [
    {
      url: 'https://docs.google.com/document/d/1uXtguYlQxqnH39xXOaLL0D4bZALN0r4x8x0UgJMsSfk/edit',
      title: 'deepcOS - Enterprise segment',
    },
  ],
  description: 'todo',
});

visionEntries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'assess',
      date: new Date('2024-02-02'),
      description: 'in review',
    },
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2024-01-15'),
      description: 'created',
    },
  ],
  key: 'product-07-2024',
  id: 'product-07-2024',
  title: 'deepcOS - Postmarkt surveillance',
  quadrant: 'product',
  links: [
    {
      url: 'https://docs.google.com/document/d/1n75ww-L-dCaJ0Sp0CGp3ugb2IomPUoN_-Soq9EcGc3g/edit',
      title: 'deepcOS - Postmarkt surveillance',
    },
  ],
  description: 'todo',
});

visionEntries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'assess',
      date: new Date('2024-02-02'),
      description: 'in review',
    },
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2024-01-15'),
      description: 'created',
    },
  ],
  key: 'product-08-2024',
  id: 'product-08-2024',
  title: 'deepcOS - Inteligent routing',
  quadrant: 'product',
  links: [
    {
      url: 'https://docs.google.com/document/d/1-3vFWEw3Fis6zIZxeHf6F7nVwlwpeLlXi9WnzOYZ7hA/edit',
      title: 'deepcOS - Inteligent routing',
    },
  ],
  description: 'todo',
});

visionEntries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'hold',
      date: new Date('2024-01-16'),
      description: 'created',
    },
  ],
  key: 'product-09-2024',
  id: 'product-09-2024',
  title: 'deepcOS - AI Catalyst',
  quadrant: 'product',
  links: [
    {
      url: 'about:blanck',
      title: 'deepcOS - AI Catalyst',
    },
  ],
  description: 'todo',
});

visionEntries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-01-16'),
      description: 'created',
    },
  ],
  key: 'product-10-2024',
  id: 'product-10-2024',
  title: 'deepcOS user requirements - Deep Integration 2.0',
  quadrant: 'product',
  links: [
    {
      url: 'https://docs.google.com/document/d/1lhokBAavDdf0lSLX1GkLBW5CgxiI8h9SSGXnFyF97zQ/edit?usp=sharing',
      title: 'deepcOS user requirements - Deep Integration 2.0',
    },
  ],
  description: 'todo',
});

visionEntries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2024-02-08'),
      description: 'created',
    },
  ],
  key: 'tech-02-2024',
  id: 'tech-02-2024',
  title: 'deepcOS Technical debt',
  quadrant: 'technical',
  links: [
    {
      url: 'https://docs.google.com/document/d/108E-_qRDrad1iE7WMbMwD2d5JKKGOxBKasNid_2ITRo/edit',
      title: 'deepcOS Technical debt',
    },
  ],
  description: 'summarize technical debt across the product organisation',
});

export const mockVision: TechRadarLoaderResponse = {
  quadrants: visionQuadrants,
  rings: visionRings,
  entries: visionEntries,
};

export class DeepcTechRadarApi implements TechRadarApi {
  async load(id: string | undefined) {
    if (id === 'tech-radar') {
      return mock;
    }

    return mockVision;
  }
}
